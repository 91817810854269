.profile-pic {
    position: relative; 
    overflow: hidden;   
    display: flex;
  }
  
  .profile-pic:hover .edit {
    background-color: rgba(221, 221, 221, 0.774);
    border-radius: 50px;
    height: 100px;
    width: 100px;
    overflow: hidden;
    position: absolute;
    opacity: 1;
  }
  
  .edit {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    font-size: 16px;
    padding: 12px 24px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    opacity: 0;
  }
  
  .edit a {
  color: #000;
  }
  
  main {
  position: relative;
  margin-left: 10px;
  }
  .modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  }
  .modal-main {
  position: absolute;
  background: white;
  width: 30%;
  height: auto;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  }
  .display-block {
  display: block;
  }
  .display-none {
  display: none;
  }
  